import { memo } from 'react'
import Button from '@/components/core/Button/Button'
import Modal from '@/components/interface/Modal/Modal'
import styles from './ModalToken.module.scss'

const CONTENT = {
  before: {
    title: '$CLAY token going live February 6th.',
    content: 'Launching on Uniswap, prepare your wallets with $POL now.',
    button: 'Launch Uniswap'
  },
  after: {
    title: '$CLAY is NOW LIVE!',
    content: '',
    button: 'Buy on Uniswap'
  }
}

// Convert EST time (10 AM EST) to UTC for accurate comparison
const launchTimeUTC = new Date(Date.UTC(2025, 1, 6, 15, 0, 0)) // Feb 6, 2025, 15:00 UTC (10 AM EST)

/**
 * The `ModalToken`
 * @returns {React.ReactElement} the element
 */
function ModalToken () {
  const isAfterLaunch = new Date() >= launchTimeUTC
  const { button, content, title } = isAfterLaunch
    ? CONTENT.after
    : CONTENT.before

  return (
    <Modal.Container>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <p>{content}</p>
        <div className={styles.wrapper}>
          <Button
            Tag="a"
            target="_blank"
            tertiary
            icon="arrow_right"
            size="xl"
            href="https://app.uniswap.org/explore/tokens/polygon/0x2aB445C24C96dB13383Bb34678aDaE50c43B4BAA"
          >
            {button}
          </Button>
        </div>
      </Modal.Body>
    </Modal.Container>
  )
}

export default memo(ModalToken)
