import { memo, useEffect, useMemo, useState } from 'react'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import Modal from '@/components/interface/Modal/Modal'
import { useModalContext } from '@/components/interface/Modal/provider/ModalProvider'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import ModalNewsletter from './modal/ModalNewsletter/ModalNewsletter'
import ModalPrivacy from './modal/ModalPrivacy/ModalPrivacy'
import ModalShare from './modal/ModalShare/ModalShare'
import ModalTerms from './modal/ModalTerms/ModalTerms'
import ModalToken from './modal/ModalToken/ModalToken'

/**
 * The `AppModals`
 * @returns {React.ReactElement} the element
 */
function AppModals () {
  const { modalId, modalRef, props, showModal } = useModalContext()
  const { isReady } = useLoader()
  const [isModalsReady, setIsModalsReady] = useState(false)
  const isRunAction = useDelayedActiveState(isModalsReady, 1)

  useEffect(() => {
    if (isReady) {
      setIsModalsReady(true)
    }
  }, [isReady])

  useEffect(() => {
    if (!isRunAction) {
      return
    }

    showModal({
      id: 'app:token:promo'
    })
  }, [isRunAction])

  const output = useMemo(() => {
    if (!modalRef.current) {
      return null
    }

    if (modalId === 'app:privacy') {
      return (
        <Modal.Portal>
          <ModalPrivacy />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:terms') {
      return (
        <Modal.Portal>
          <ModalTerms />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:newsletter') {
      return (
        <Modal.Portal>
          <ModalNewsletter />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:share') {
      return (
        <Modal.Portal>
          <ModalShare {...props} />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:token:promo') {
      return (
        <Modal.Portal>
          <ModalToken {...props} />
        </Modal.Portal>
      )
    }
  }, [modalId, props])

  return output
}

export default memo(AppModals)
